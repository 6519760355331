<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Flexible Spending Account (FSA)</h1>
      <!-- <h3>The 2023 limit for contributions is $3,050.</h3> -->
      <router-link class="text-xl" to="/support/articles/18310279162131"
        >See FSA Limits</router-link
      >
      <p>
        Employers may offer a health Flexible Spending Account (FSA) as part of
        their employee benefits programs. The FSA is funded front loaded by the
        employer and the employee’s salary reduction repays the employer for the
        agreed amount. This money can be used to reimburse employees for
        expenses related to medical insurance deductibles and copays,
        out-of-pocket medical treatments or supplies, and dependent healthcare
        expenses.
      </p>
      <p>
        When employees contribute to a flexible spending arrangement, no
        employment or federal income taxes are deducted from the contribution.
        Any contributions to an FSA by an employer are excluded from the
        employee’s gross income which reduces the tax payments of the employer.
        <u>Both employee and employer save money when using an FSA!</u>
      </p>
    </div>

    <info-dropdown title="Simple example of how pre-tax works" :headerLvl="2">
      <p>
        Pre-tax dollars mean you get access to the funds before social security,
        federal and state tax, etc. Say you work 10 hours at $10 ($100) and you
        wanted to set aside $25 for eligible expenses. Instead of getting taxed
        on the full $100, you would only get taxed on the $75 since the $25 was
        taken out before!
      </p>
      <p>
        Average taxes in the United States are about 20%, if you were taxed on
        the $100 that totals $20 of taxes. If you were taxed on only the $75
        that totals only $15 of taxes. The yearly maximum for an FSA is $3,200.
        If you had a family FSA and made $50,000 annually- you would only be
        taxed $9,450 compared to the full $10,000.
      </p>
      <p>
        If you did not set funds aside, you would pay an extra $550 of taxes by
        waiting to pay for your share of medical expenses after-tax.
      </p>
    </info-dropdown>

    <info-dropdown title="Important features of the plan" :headerLvl="2">
      <p>All FSAs must meet certain legal requirements:</p>
      <p>
        Only expenses that are incurred during the plan year can be reimbursed.
        “Incurred” means the date of service or purchase, it is not based on
        when the expenses are paid. For example, an expense is incurred in
        December 2023 when an individual visits the medical practitioner, not
        when the practitioner’s bill is received or paid by the patient in
        January 2024. Only 2023 funds could be used to pay for this visit.
      </p>
      <p>
        <b
          >Any money remaining in a flexible spending account at the end of the
          plan year cannot be carried over to the next year.</b
        >
        The only exception is if the employer allows for the 2023 IRS maximum
        rollover of $610. The 2024 rollover maximum is $640.
      </p>
      <p>
        If an employee terminated with their employer, they would have access to
        the funds via claims for expenses incurred from the first day of the
        plan year to the date of termination. Card transactions end on the date
        of termination. Payroll contributions end with the former employee’s
        last paycheck.
      </p>
      <p>
        Employees are not required to have any other insurance to participate.
        Unfortunately, self-employed persons cannot have a Health FSA.
      </p>
      <p>
        An FSA is allowed under the Internal Revenue Code Section 125 and covers
        all pre-tax dollars for non-reimbursed medical, dental and dependent
        care expenses. Other items that may be covered include insurance
        premiums, deductibles, co-payments, and non-covered services.
      </p>
      <p>
        Click
        <router-link to="/whats-covered" class="inline-link">here</router-link>
        to see a full list of eligible items and required documentation to make
        the items eligible!
      </p>
      <img
        class="w-auto max-w-full h-44"
        src="@/assets/ElevateCard.png"
        alt="Card Image"
      />
      <h3>What is the benefit of a Debit Card?</h3>
      <ul>
        <li>It conveniently helps the participant with cash flow</li>
        <li>The card pays directly to the provider at the point of sale</li>
        <li>It adds a convenience to accessing participant funds</li>
      </ul>
      <p>
        Not all merchants have their card machine coded properly for the card to
        be approved. Transactions may not be automatically approved upon swiping
        your card at locations such as Target or Wal-greens that sell both
        eligible and ineligible items. Documentation requests may still occur
        for eligible items or services.
      </p>
    </info-dropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown
  }
};
</script>
